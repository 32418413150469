<template>
  <v-card class="cursor px-2 pb-0" @click="openInfo" tile :ripple="false">
    <v-card-title class="pb-0 pt-2 pl-3" v-if="reward.group">
      <v-chip small label color="app-green" dark class="ml-0 mb-0">
        <span class="text-body-2 font-weight-medium">
          {{ reward.group.name }}
        </span>
      </v-chip>
    </v-card-title>
    <div class="d-flex align-center">
      <v-card-text class="text-subtitle-1 pl-3">
        <span class="one-line">{{ reward.name }}</span>
      </v-card-text>
      <div class="d-flex align-center pr-2">
        <ruby-label :ruby="reward.ruby"></ruby-label>
      </div>
    </div>
    <v-card-actions class="pt-0 pb-2">
      <reward-type-label :reward="reward" class="mr-2"></reward-type-label>
      <v-icon v-if="reward.requireApprove" small color="app-orange">
        mdi-circle
      </v-icon>
      <v-spacer></v-spacer>
      <v-btn
        v-if="canRedeemReward"
        small
        dark
        color="app-green"
        depressed
        rounded
        @click.stop="redeem"
      >
        <span class="font-weight-medium">
          {{ $t('rewards.redeem') }}
        </span>
      </v-btn>
      <v-chip
        v-else-if="reward.approveRequested"
        color="app-orange"
        text-color="white"
        class="font-weight-medium text-uppercase"
      >
        {{ $t('rewards.waitingApproval') }}
      </v-chip>
    </v-card-actions>
    <v-divider class="mt-2" light v-if="toggleApproval"></v-divider>
    <v-card-actions v-if="toggleApproval" class="justify-center">
      <approval-actions @approve="approveReward"></approval-actions>
    </v-card-actions>
  </v-card>
</template>

<script>
import RewardTypeLabel from '../../rewards/RewardTypeLabel';
import RubyLabel from '../../shared/RubyLabel';
import ConfirmModal from '../../shared/ConfirmModal';
import GroupRewardInfoModal from '../../groups/rewards/GroupRewardInfoModal';
import ApprovalActions from '../../groups/ApprovalActions';
import {
  REDEEM_GROUP_REWARD,
  APPROVE_GROUP_REWARD
} from '../../../store/rewards/types';
import rewardTypes from '../../../enums/reward.types';

const GROUP_REWARD_INFO_MODAL_CONFIG = {
  width: '400px'
};

export default {
  name: 'group-reward',
  components: {
    RewardTypeLabel,
    RubyLabel,
    ApprovalActions
  },
  props: {
    reward: Object,
    isLeader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    canRedeemReward() {
      return !this.isLeader && !this.reward.approveRequested;
    },
    canApproveReward() {
      return this.isLeader && this.reward.approveRequested;
    },
    toggleApproval() {
      return (
        this.isLeader &&
        this.reward.requireApprove &&
        this.reward.approveRequested
      );
    }
  },
  data() {
    return {
      rewardTypes
    };
  },
  methods: {
    async redeem() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$root.$t('rewards.redeemRewardConfirm')
      });

      if (confirmed) {
        await this.redeemReward();
      }
    },
    async redeemReward() {
      const { id } = this.$route.params;
      const groupId = this.reward.group ? this.reward.group.id : id;

      await this.$store.dispatch(REDEEM_GROUP_REWARD, {
        groupId,
        reward: this.reward
      });

      if (this.reward.requireApprove) {
        this.$notify({
          type: 'success',
          text: this.$root.$t('rewards.groupRewardWasSentForApproval')
        });
      } else {
        this.$notify({
          type: 'info',
          text: this.$root.$t('rewards.redeemedGroupReward', {
            ruby: this.reward.ruby
          })
        });
      }
    },
    openInfo() {
      this.$root.$modal.show(
        GroupRewardInfoModal,
        {
          reward: this.reward
        },
        GROUP_REWARD_INFO_MODAL_CONFIG
      );
    },
    async approveReward(approved) {
      const { id } = this.$route.params;

      this.$store.dispatch(APPROVE_GROUP_REWARD, {
        groupId: id,
        userId: this.reward.userId,
        rewardId: this.reward.id,
        approved
      });

      if (approved) {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('rewards.rewardApproved')
        });
      } else {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('rewards.rewardRefused')
        });
      }
    }
  }
};
</script>
