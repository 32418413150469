<template>
  <div>
    <v-icon small v-if="status === missionStatuses.active" color="app-green"
      >mdi-circle</v-icon
    >
    <v-icon
      small
      v-else-if="status === missionStatuses.pending"
      color="app-blue"
      >mdi-circle</v-icon
    >
    <v-icon small v-else-if="status === missionStatuses.failed" color="app-red"
      >mdi-close-circle-outline</v-icon
    >
    <v-icon
      small
      v-else-if="status === missionStatuses.waitingApproval"
      color="app-orange"
      >mdi-circle</v-icon
    >
    <v-icon
      small
      v-else-if="status === missionStatuses.archived"
      color="app-orange"
      >mdi-archive</v-icon
    >
    <v-icon small v-else color="app-green">mdi-check-circle</v-icon>
  </div>
</template>

<script>
import missionStatuses from '../../enums/mission.statuses';

export default {
  name: 'mission-small-status-label',
  props: {
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    missionStatuses() {
      return missionStatuses;
    }
  }
};
</script>
