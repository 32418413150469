<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular">
        {{ $t('rewards.reward') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container class="px-4 pt-0">
      <v-card-title class="pb-1 pl-0" v-if="reward.group">
        <v-chip class="ml-0" small label color="app-green" dark>
          <span class="text-body-2 font-weight-medium">
            {{ reward.group.name }}
          </span>
        </v-chip>
      </v-card-title>
      <v-row align="center" class="pt-1 pb-2">
        <v-col cols="12" class="pb-0">
          <v-card-title class="text-subtitle-1 pl-0 py-0">
            <span class="font-weight-medium">
              {{ reward.name }}
            </span>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row class="mt-2 mb-1">
        <v-col cols="12" class="py-0">
          <span class="font-weight-bold">
            {{ $t('common.requireApprove') }}:
          </span>
          <span v-if="reward.requireApprove">{{ $t('common.yes') }}</span>
          <span v-else>{{ $t('common.no') }}</span>
        </v-col>
      </v-row>
      <v-row v-if="approveMarked" class="mb-2">
        <v-col class="py-0">
          <span class="font-weight-bold pr-2">
            {{ $t('common.approved') }}:
          </span>
          <v-icon v-if="reward.approved" small color="app-green">
            mdi-thumb-up
          </v-icon>
          <v-icon v-else small color="app-red">mdi-thumb-down</v-icon>
        </v-col>
      </v-row>
      <v-card-actions class="py-1 pl-0">
        <reward-type-label :reward="reward"></reward-type-label>
        <v-spacer></v-spacer>
        <ruby-label :ruby="reward.ruby"></ruby-label>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import RewardTypeLabel from '../../rewards/RewardTypeLabel';
import RubyLabel from '../../shared/RubyLabel';

export default {
  name: 'group-reward-info-modal',
  props: {
    reward: Object
  },
  components: {
    RewardTypeLabel,
    RubyLabel
  },
  computed: {
    approveMarked() {
      return (
        this.reward.requireApprove &&
        this.reward.approved !== null &&
        this.reward.approved !== undefined
      );
    }
  }
};
</script>
