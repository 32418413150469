<template>
  <v-card class="cursor pa-2" @click="openInfoTask" tile :ripple="false">
    <v-container class="pa-0">
      <v-card-title
        class="pr-0 pl-2 pt-0"
        v-bind:class="{ 'pb-0': isLeader, 'pb-2': !isLeader }"
      >
        <v-chip
          v-if="!isLeader && task.group"
          small
          label
          color="app-green"
          dark
        >
          <span class="text-body-2 font-weight-medium">
            {{ task.group.name }}
          </span>
        </v-chip>
      </v-card-title>
      <v-row align="center" justify="center" class="py-2">
        <v-col cols="2" class="pt-0">
          <v-btn
            v-if="task.completed"
            text
            icon
            large
            color="app-green"
            @click.stop="reopenTask()"
            :disabled="isLeader"
          >
            <v-icon>mdi-check-circle</v-icon>
          </v-btn>
          <v-btn
            v-else
            text
            large
            icon
            color="app-green"
            @click.stop="completeTask()"
            :disabled="isLeader"
          >
            <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col
          class="pt-0 pl-md-0"
          v-bind:class="{
            'col-10': !task.requireApprove,
            'col-9': task.requireApprove
          }"
        >
          <v-card-title class="text-subtitle-1 pl-0 py-0">
            <span class="two-lines font-weight-regular">
              {{ task.title }}
            </span>
          </v-card-title>
          <v-card-text class="text-body-2 pl-0 pt-0 pb-2" v-if="task.notes">
            <span class="font-weight-light one-line">
              {{ task.notes }}
            </span>
          </v-card-text>
        </v-col>
        <v-col v-if="task.requireApprove" cols="1" class="pt-0 px-0">
          <v-icon v-if="!approveMarked" small color="app-orange">
            mdi-circle
          </v-icon>
          <v-icon v-else-if="task.approved" small color="app-green">
            mdi-thumb-up
          </v-icon>
          <v-icon v-else small color="app-red">mdi-thumb-down</v-icon>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="pt-0 pb-1">
      <task-tags v-if="task.tags.length > 0" :tags="task.tags"></task-tags>
      <div v-else>&nbsp;</div>
      <v-spacer></v-spacer>
      <v-row align="center" justify="end" class="pr-1">
        <ruby-label :ruby="task.ruby" :text-color="rubyColorText"></ruby-label>
      </v-row>
    </v-card-actions>
    <v-divider class="mt-2" light v-if="toggleApproval"></v-divider>
    <v-card-actions v-if="toggleApproval" class="justify-center">
      <div v-if="!isLeader">
        <span class="text-uppercase app-blue--text text-body-2">
          {{ $t('tasks.waitingForApprove') }}
        </span>
      </div>
      <approval-actions v-else @approve="approveTask"></approval-actions>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfirmModal from '@/components/shared/ConfirmModal';
import MemberTaskInfoModal from './MemberTaskInfoModal';
import ApprovalActions from '../../groups/ApprovalActions';
import RubyLabel from '../../shared/RubyLabel';
import TaskTags from '../../tasks/TaskTags';
import AddResultModal from '../../tasks/AddResultModal';
import {
  COMPLETE_GROUP_TASK,
  REOPEN_GROUP_TASK,
  APPROVE_GROUP_TASK
} from '../../../store/tasks/types';
import multiNotificationsMixin from '../../../mixins/multi.notifications.mixin';
import tasksRubyColorMixin from '../../../mixins/task.ruby.color.mixin';

const GROUP_TASK_INFO_MODAL_CONFIG = {
  width: '400px'
};

export default {
  name: 'member-task',
  props: {
    task: Object,
    isLeader: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RubyLabel,
    TaskTags,
    ApprovalActions
  },
  mixins: [multiNotificationsMixin, tasksRubyColorMixin],
  computed: {
    ...mapGetters(['currentUser']),
    approveMarked() {
      return this.task.approved !== null && this.task.approved !== undefined;
    },
    toggleApproval() {
      return (
        this.task.requireApprove &&
        this.task.approveRequested &&
        this.task.completed
      );
    }
  },
  methods: {
    openResultModal() {
      return this.$root.$modal.show(AddResultModal, {
        task: this.task
      });
    },
    async completeTask() {
      if (this.task.requireResult) {
        const result = await this.openResultModal();

        if (!result) return;

        this.task.result = result;
      }

      const groupId = this.$route.params.id || this.task?.group.id;

      const { diff } = await this.$store.dispatch(COMPLETE_GROUP_TASK, {
        groupId,
        task: this.task
      });

      if (!diff) return;

      this.notifySuccess(diff, 'users.stats.success');
    },
    async reopenTask() {
      let confirmed = true;

      if (this.task.approved) {
        confirmed = await this.$root.$modal.show(ConfirmModal, {
          text: this.$i18n.t('tasks.reopenApprovedGroupTaskConfirm')
        });
      }

      if (this.task.approveRequested) {
        confirmed = await this.$root.$modal.show(ConfirmModal, {
          text: this.$i18n.t('tasks.reopenApproveRequestedGroupTaskConfirm')
        });
      }

      if (!confirmed) return;

      const groupId = this.$route.params.id || this.task.group.id;

      const { diff } = await this.$store.dispatch(REOPEN_GROUP_TASK, {
        groupId,
        task: this.task
      });

      if (!diff) return;

      this.notifyFailure(diff, 'users.stats.failure');
    },
    openInfoTask() {
      this.$root.$modal.show(
        MemberTaskInfoModal,
        {
          task: this.task
        },
        GROUP_TASK_INFO_MODAL_CONFIG
      );
    },
    async approveTask(approved) {
      const { id } = this.$route.params;

      this.$store.dispatch(APPROVE_GROUP_TASK, {
        groupId: id,
        userId: this.task.userId,
        taskId: this.task.id,
        approved
      });

      if (approved) {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('tasks.taskApproved')
        });
      } else {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('tasks.taskRefused')
        });
      }
    }
  }
};
</script>
