<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular">
        {{ $t('tasks.resultOfTheTask') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="pa-6 pb-0">
      <v-form ref="resultForm">
        <v-textarea
          solo
          v-model="result"
          :placeholder="$t('tasks.resultOfTheTask')"
          :rules="[rules.required]"
        ></v-textarea>
      </v-form>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="app-blue" text @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from '../../common/validators';

export default {
  name: 'add-result-modal',
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      result: '',
      loading: false,
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField'))
      }
    };
  },
  created() {
    this.result = this.task.result;
  },
  methods: {
    async save() {
      if (!this.$refs.resultForm.validate()) {
        return;
      }

      this.$emit('close', this.result);
    }
  }
};
</script>
