<template>
  <v-bottom-navigation
    v-model="selected"
    background-color="app-blue"
    dark
    :value="true"
    @change="onSelect"
    fixed
    grow
  >
    <v-btn dark>
      <span>{{ $t('missions.missions') }}</span>
      <v-icon>mdi-calendar</v-icon>
    </v-btn>

    <v-btn dark>
      <span>{{ $t('tasks.tasks') }}</span>
      <v-icon>mdi-format-list-checks</v-icon>
    </v-btn>

    <v-btn dark>
      <span>{{ $t('rewards.rewards') }}</span>
      <v-icon>mdi-medal</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapGetters } from 'vuex';
import { SELECT_BOTTOM_NAV_ITEM } from '../../store/shared/types';

const DASHBOARD_ITEMS_SELECTOR = '#dashboard-items';

export default {
  name: 'bottom-dashboard-nav',
  computed: {
    ...mapGetters(['bottomNavItemsVisibility'])
  },
  data() {
    return {
      selected: 0
    };
  },
  created() {
    this.selected = this.bottomNavItemsVisibility.findIndex(i => i);
  },
  methods: {
    onSelect(index) {
      this.$store.dispatch(SELECT_BOTTOM_NAV_ITEM, index);
      this.$vuetify.goTo(DASHBOARD_ITEMS_SELECTOR, { easing: 'easeOutQuint' });
    }
  }
};
</script>
