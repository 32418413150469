<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular">
        {{ $t(`tasks.${task.type}`) }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container class="px-4 pt-3">
      <v-card-title class="pb-1 pl-0 pt-0" v-if="task.group">
        <v-chip class="ml-0" small label color="app-green" dark>
          <span class="text-body-2 font-weight-medium">
            {{ task.group.name }}
          </span>
        </v-chip>
      </v-card-title>
      <v-row class="pt-1 pb-2">
        <v-col cols="10" class="pb-0">
          <v-card-title class="text-subtitle-1 pl-0 py-0">
            <span class="font-weight-medium">
              {{ task.title }}
            </span>
          </v-card-title>
          <v-card-text class="text-body-2 pl-0 py-0" v-if="task.notes">
            <span class="font-weight-light one-line">
              {{ task.notes }}
            </span>
          </v-card-text>
        </v-col>
        <v-col cols="2" class="pb-0">
          <v-icon
            v-if="task.completed"
            color="grey lighten-1"
            class="fas fa-check-circle"
          ></v-icon>
          <v-icon v-else color="grey lighten-1" class="far fa-circle"></v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <span class="font-weight-bold">
            {{ $t('common.requireApprove') }}:
          </span>
          <span v-if="task.requireApprove">{{ $t('common.yes') }}</span>
          <span v-else>{{ $t('common.no') }}</span>
        </v-col>
      </v-row>
      <v-row v-if="approveMarked" class="mb-2">
        <v-col class="py-0">
          <span class="font-weight-bold pr-2">
            {{ $t('common.approved') }}:
          </span>
          <v-icon v-if="task.approved" small color="app-green">
            mdi-thumb-up
          </v-icon>
          <v-icon v-else small color="app-red">mdi-thumb-down</v-icon>
        </v-col>
      </v-row>
      <v-row v-if="task.result" class="mt-4">
        <v-col class="py-0">
          <h4 class="font-weight-bold black--text">
            {{ $t('tasks.resultOfTheTask') }}:
          </h4>
          <span>{{ task.result }}</span>
        </v-col>
      </v-row>
      <v-card-actions class="py-2 pl-0 mt-4">
        <task-tags :tags="task.tags"></task-tags>
        <v-spacer></v-spacer>
        <v-row align="center" justify="end" class="pr-1">
          <ruby-label
            :ruby="task.ruby"
            :text-color="rubyColorText"
          ></ruby-label>
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import TaskTags from '../../tasks/TaskTags';
import RubyLabel from '../../shared/RubyLabel';
import tasksRubyColorMixin from '../../../mixins/task.ruby.color.mixin';

export default {
  name: 'member-task-info-modal',
  props: {
    task: Object
  },
  components: {
    TaskTags,
    RubyLabel
  },
  mixins: [tasksRubyColorMixin],
  computed: {
    approveMarked() {
      return (
        this.task.requireApprove &&
        this.task.approved !== null &&
        this.task.approved !== undefined
      );
    }
  }
};
</script>
